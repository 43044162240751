<template>
  <div class="min-h-screen font-sans bg-gray-200">
    <div v-if="!$auth.isAuthenticated">
      <slot />
    </div>
    <div
      v-if="$auth.isAuthenticated"
      class="container min-w-full px-10 py-12"
    >
      <nav
        class="flex items-center justify-between py-3 mb-8 border-b border-gray-400"
      >
        <div class="text-2xl font-semibold text-gray-700">
          Backoffice
        </div>
        <div
          v-if="featuresLoaded"
          class="flex items-center"
        >
          <router-link
            to="/dashboard"
            exact
            class="mx-4 text-lg font-semibold text-gray-500"
            active-class="text-gray-800"
          >
            <fa
              :icon="faChartArea"
              class="mr-2"
            />Dashboard
          </router-link>
          <router-link
            to="/companies"
            exact
            class="ml-6 mr-2 text-lg font-semibold text-gray-500"
            active-class="text-gray-800"
          >
            <fa
              :icon="faBuilding"
              class="mr-2"
            />Immobilienfirmen
          </router-link>
          <router-link
            v-if="hasRole(ROLES.SUPER_ADMIN)"
            to="/landingpages"
            class="mx-4 text-lg font-semibold text-gray-500"
            active-class="text-gray-800"
            exact
          >
            <fa
              :icon="faSitemap"
              class="mr-2"
            />Landingpages
          </router-link>
          <router-link
            v-if="hasRole(ROLES.SUPER_ADMIN)"
            to="/campaigns"
            exact
            class="ml-6 mr-2 text-lg font-semibold text-gray-500"
            active-class="text-gray-800"
          >
            <fa
              :icon="faBullhorn"
              class="mr-2"
            />Kampagnen
          </router-link>
          <router-link
            v-if="isFeatureActive(featureNames.STATISTICS)"
            to="/statistics"
            exact
            class="mx-4 text-lg font-semibold text-gray-500"
            active-class="text-gray-800"
          >
            <fa
              :icon="faChartArea"
              class="mr-2"
            />Statistiken
          </router-link>
          <router-link
            v-if="isFeatureActive(featureNames.BACKOFFICE_POSTS) && hasRole(ROLES.SUPER_ADMIN)"
            to="/posts"
            exact
            class="mx-4 text-lg font-semibold text-gray-500"
            active-class="text-gray-800"
          >
            <fa
              :icon="faPhotoFilm"
              class="mr-2"
            />Posts
          </router-link>
          <div class="py-6 mx-8 border-r border-gray-400" />
          <div class="flex items-center">
            <img
              :src="$auth.user.picture"
              class="w-10 h-10 mr-2 bg-gray-800 rounded-full"
            >
            <div class="flex flex-col items-start leading-snug">
              <div class="font-semibold text-gray-600">
                {{ $auth.user.name }}
              </div>
              <button
                class="text-sm font-semibold text-gray-700"
                @click="logout()"
              >
                Log out
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div class="bg-white rounded-lg shadow-xl">
        <slot />
      </div>
    </div>
    <div class="container flex justify-end mx-auto">
      <button
        v-if="showAccessToken"
        class="mb-6 text-sm text-gray-400 hover:text-gray-600"
        @click="copyTokenToClipboard"
      >
        <fa
          :icon="faKey"
          class="mr-2"
        />Access Token kopieren
      </button>
    </div>
  </div>
</template>

<script>
import '@/assets/css/tailwind.css'
import {
  faKey,
  faBuilding,
  faChartArea,
  faClipboardList,
  faBullhorn,
  faPhotoFilm,
  faSitemap
} from '@fortawesome/free-solid-svg-icons'
import featureMixin from '@/mixins/feature'
import role from '@/mixins/role'

export default {
  mixins: [featureMixin, role],
  data: () => ({
    faKey,
    faBuilding,
    faChartArea,
    faClipboardList,
    faBullhorn,
    faPhotoFilm,
    faSitemap
  }),
  computed: {
    featuresLoaded () {
      return this.$features.loaded
    },
    showAccessToken () {
      return this.$auth.isAuthenticated && process.env.VUE_APP_SHOW_ACCESS_TOKEN === 'true'
    }
  },
  methods: {
    logout () {
      this.$auth.logout()
    },

    async copyTokenToClipboard () {
      const token = await this.$auth.getTokenSilently()
      this.$clipboard(token)
    }
  }
}
</script>

<template>
  <div class="h-full ">
    <div class="modal-content">
      <div
        v-if="company"
        class="flex flex-col"
      >
        <div class="w-full p-10">
          <div class="w-full p-10 bg-yellow-200 bg-opacity-75 border-2 border-yellow-500 rounded-md">
            <div class="grid grid-cols-8">
              <div class="col-span-1">
                <span class="text-lg font-bold">Aktueller Status: </span>
              </div>
              <div class="col-span-7 pl-2">
                <div class="flex flex-col w-2/3">
                  <span class="text-lg font-bold">{{ companyStateName }}</span>
                  <span v-html="companyStateInfo" />
                </div>
              </div>
            </div>
            <div class="grid grid-cols-8 mt-4">
              <div class="col-span-1">
                <span class="text-lg font-bold">Nächster Schritt: </span>
              </div>
              <div class="col-span-7 pl-2">
                <div class="flex flex-col">
                  <span
                    class="w-2/3 mb-2"
                    v-html="companyStateNextInfo"
                  />
                  <div class="flex">
                    <div class="flex flex-row items-center w-full">
                      <div
                        v-for="event in preparedEvents"
                        :key="event"
                        class="flex my-2"
                      >
                        <button
                          class="mr-4 btn-primary"
                          @click="updateState(event)"
                        >
                          {{ getCompanyStateEventName(event) || event }}
                        </button>
                      </div>
                    </div>
                    <div
                      v-if="canDeactivate"
                      class="flex flex-row items-center justify-end w-full"
                    >
                      <button
                        class="btn-danger"
                        @click="showDeactivateCompanyModal()"
                      >
                        Deaktivieren
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeactivateCompanyModal from '@/components/modals/company/state/deactivate'
import UPDATE_COMPANY_STATE from '@/graphql/UpdateCompanyState.gql'
import GET_COMPANY_STATE from '@/graphql/GetCompanyState.gql'
import featureMixin from '@/mixins/feature'
import { getCompanyStateEventName, getCompanyStatesName, getCompanyStatesInfo, getCompanyStatesNextInfo } from '@/config'
import eventBus, { eventNames } from '@/lib/eventBus'

export default {
  mixins: [featureMixin],
  props: {
    companyId: {
      type: String,
      required: true
    },
    companyName: {
      type: String,
      default: ''
    },
    isOnboardingDataComplete: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    canDeactivate () {
      return this.company && this.company.nextEvents.includes('DEACTIVATE')
    },
    preparedEvents () {
      if (!this.company) return []
      const unavailableStates = ['DEACTIVATE']
      if (!this.isOnboardingDataComplete) unavailableStates.push('ACTIVATE')
      return this.company.nextEvents.filter(val => !unavailableStates.includes(val))
    },
    state () {
      const last = (list) => list[list.length - 1]
      return last(this.company.state.stringValues)
    },
    companyStateName () {
      return getCompanyStatesName(this.state) || this.state
    },
    companyStateInfo () {
      return getCompanyStatesInfo(this.state)
    },
    companyStateNextInfo () {
      return getCompanyStatesNextInfo(this.state)
    }
  },

  methods: {
    getCompanyStateEventName,
    async updateState (event) {
      try {
        const {
          data: {
            updateCompanyState: {
              isOnboardingDataComplete
            }
          }
        } = await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_STATE,
          variables: {
            input: {
              companyId: this.companyId,
              event
            }
          }
        })
        if ((event === 'ONBOARDED' || event === 'ACTIVATE') && !isOnboardingDataComplete) {
          eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'Fehlende Daten. Alle Pflichtfelder müssen ausgefüllt sein.' })
        }
      } catch (error) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'Fehler beim Updaten.' })
      }
    },
    showDeactivateCompanyModal () {
      this.$modal.show(
        DeactivateCompanyModal,
        {
          companyId: this.companyId,
          companyName: this.companyName
        },
        { width: 800 }
      )
    }
  },
  apollo: {
    company: {
      query: GET_COMPANY_STATE,
      variables () {
        return { id: this.companyId }
      }
    }
  }
}
</script>

<template>
  <div class="min-h-screen">
    <div class="flex items-center justify-between px-4 py-8 bg-gray-100 rounded-t-lg">
      <input
        v-model="search"
        type="text"
        placeholder="Suchen nach: Name, Adresse, GTM, Kd-Nr., Status"
        class="w-2/5"
      >

      <router-link
        to="/companies/create"
        class="flex-shrink ml-16 font-semibold text-gray-600 uppercase"
      >
        Neue Immobilienfirma erstellen
      </router-link>
    </div>
    <div class="flex items-center justify-between px-4 py-4 text-gray-600 bg-gray-100">
      <p class="">
        {{ filteredCompanies.length }} Firmen
        <a
          href="#"
          class="pl-6 underline"
          @click.prevent="clearAllFilter"
        >Alle Filter und Suche löschen</a>
      </p>
      <div class="relative">
        <button
          class="px-2 py-1 border rounded"
          @click="showExportModal = !showExportModal"
        >
          <fa
            :icon="faFileCsv"
            class="self-center inline-block mr-2"
          />
          Export
        </button>
        <div
          v-if="showExportModal"
          v-click-outside="hideExportModal"
          class="absolute right-0 z-10 flex flex-col p-2 mt-2 overflow-visible font-light text-left whitespace-no-wrap bg-white border rounded-sm shadow-sm"
        >
          <ul class="">
            <li class="mx-2 my-1">
              <button @click="exportCompanies">
                Exportiere alle Immobilienfirmen
              </button>
            </li>
            <li class="mx-2 my-1">
              <button @click="exportCompanies({extended:true})">
                Exportiere Firmen mit Custom Urls
              </button>
            </li>
            <li class="mx-2 my-1">
              <button @click="exportUsers">
                Exportiere alle Benutzer
              </button>
            </li>
            <Feature :feature-slug="featureNames.CONTRACT">
              <div>
                <li class="mx-2 my-1">
                  <button @click="showExportCompaniesWithInvoiceModal">
                    Exportiere alle Firmen mit Rechnung
                  </button>
                </li>
                <li class="mx-2 my-1">
                  <button @click="showExportCompaniesWithoutInvoiceModal">
                    Exportiere Aktive Firmen ohne Rechnung
                  </button>
                </li>
                <li class="mx-2 my-1">
                  <button @click="showExportPricehubbleModal">
                    Exportiere aktive Firmen, die Pricehubble benutzen
                  </button>
                </li>
                <li class="mx-2 my-1">
                  <button @click="showExportAbacusCompanyModal">
                    Exportiere Abacus Kunden (XML für Import)
                  </button>
                </li>
                <li class="mx-2 my-1">
                  <button @click="showExportBillingCompanyModal">
                    Exportiere Abacus Kunden (CSV zur Überprüfung)
                  </button>
                </li>
                <li class="mx-2 my-1">
                  <button @click="showExportAbacusContractModal">
                    Exportiere Abacus Produkte (XML für Import)
                  </button>
                </li>
                <li class="mx-2 my-1">
                  <button @click="showExportProductsModal">
                    Exportiere Abacus Produkte (CSV zur Überprüfung)
                  </button>
                </li>
                <li class="mx-2 my-1">
                  <button @click="exportContractsWithProducts">
                    Exportiere alle Vertragsdaten
                  </button>
                </li>
                <li class="mx-2 my-1">
                  <button @click="showExportExpertProductsModal">
                    Exportiere alle Experten Pakete eines Monats (pricehubble)
                  </button>
                </li>
                <li class="mx-2 my-1">
                  <button @click="showExportStartedContractsModal">
                    Exportiere alle Firmen mit Startdatum eines Monats
                  </button>
                </li>
                <li class="mx-2 my-1">
                  <button @click="showExportTerminatedContractModal">
                    Exportiere alle Firmen mit gekündigten Verträgen eines Monats
                  </button>
                </li>
              </div>
            </Feature>
          </ul>
        </div>
      </div>
    </div>
    <table class="w-full h-full border-0">
      <thead>
        <tr
          class="text-sm font-semibold tracking-wide text-left text-gray-700 uppercase bg-gray-200"
        >
          <th class="relative hidden w-1/12 p-4 cursor-pointer sm:table-cell">
            <div
              class="flex justify-between"
              @click="showStateFilter = !showStateFilter"
            >
              <div>Status</div>
              <fa
                :icon="faFilter"
                class="self-center inline-block"
                :class="{'text-teal-500':stateFilter.length}"
              />
            </div>
            <div
              v-if="showStateFilter"
              v-click-outside="hideStateFilter"
              class="absolute flex flex-col p-2 mt-2 overflow-visible font-light whitespace-no-wrap bg-white border rounded-sm shadow-sm"
            >
              <div
                v-for="state in COMPANY_STATES"
                :key="state.value"
                class="px-2 py-1 cursor-pointer"
                @click="toggleFilter('stateFilter', state.value)"
              >
                <fa
                  :icon="faCheck"
                  class="self-center inline-block mr-2"
                  :class="{'text-teal-500':stateFilter.includes(state.value)}"
                />
                {{ state.name }}
              </div>
            </div>
          </th>
          <th class="w-2/12 p-4">
            Immobilienfirma
          </th>
          <th class="hidden w-2/12 p-4 sm:table-cell">
            <SortableField
              :sortable-items="companies"
              field="createdAt"
            >
              Erstelldatum
            </SortableField>
          </th>
          <th class="hidden w-2/12 p-4 sm:table-cell">
            Adresse
          </th>
          <th class="hidden w-1/12 p-4 xl:table-cell">
            Repräsentant
          </th>
          <th class="hidden w-2/12 p-4 xl:table-cell">
            GTM-Id
          </th>
          <th class="relative hidden w-1/12 p-4 cursor-pointer md:table-cell">
            <div
              class="flex justify-between"
              @click="showCountryFilter = !showCountryFilter"
            >
              <div>Land</div>
              <fa
                :icon="faFilter"
                class="self-center inline-block ml-2 cursor-pointer"
                :class="{'text-teal-500':countryFilter.length}"
              />
            </div>
            <div
              v-if="showCountryFilter"
              v-click-outside="hideCountryFilter"
              class="absolute flex flex-col p-2 mt-2 overflow-visible font-light whitespace-no-wrap bg-white border rounded-sm shadow-sm"
            >
              <div
                v-for="country in countries"
                :key="country"
                class="px-2 py-1 cursor-pointer"
                @click="toggleFilter('countryFilter', country)"
              >
                <fa
                  :icon="faCheck"
                  class="self-center inline-block mr-2"
                  :class="{'text-teal-500':countryFilter.includes(country)}"
                />
                {{ country }}
              </div>
            </div>
          </th>
          <Feature
            v-slot="{feature}"
            :feature-slug="featureNames.VARIANT"
          >
            <th
              v-if="hasMultipleVariants(feature.config.variants)"
              class="relative hidden w-1/12 p-4 cursor-pointer lg:table-cell"
            >
              <div
                class="flex justify-between"
                @click="showVariantFilter = !showVariantFilter"
              >
                <div>Variante</div>
                <fa
                  :icon="faFilter"
                  class="self-center inline-block"
                  :class="{'text-teal-500':variantFilter.length}"
                />
              </div>
              <div
                v-if="showVariantFilter"
                v-click-outside="hideVariantFilter"
                class="absolute flex flex-col p-2 mt-2 overflow-visible font-light whitespace-no-wrap bg-white border rounded-sm shadow-sm"
              >
                <div
                  v-for="variant in variants"
                  :key="variant"
                  class="px-2 py-1 cursor-pointer"
                  @click="toggleFilter('variantFilter', variant)"
                >
                  <fa
                    :icon="faCheck"
                    class="self-center inline-block mr-2"
                    :class="{'text-teal-500':variantFilter.includes(variant)}"
                  />
                  {{ variant }}
                </div>
              </div>
            </th>
          </Feature>
          <th class="relative hidden w-1/12 p-4 cursor-pointer lg:table-cell">
            <div
              class="flex justify-between"
              @click="showProductTypeFilter = !showProductTypeFilter"
            >
              <div>Vertrag</div>
              <fa
                :icon="faFilter"
                class="self-center inline-block"
                :class="{'text-teal-500':productTypeFilter.length}"
              />
            </div>
            <div
              v-if="showProductTypeFilter"
              v-click-outside="hideProductTypeFilter"
              class="absolute flex flex-col p-2 mt-2 overflow-visible font-light whitespace-no-wrap bg-white border rounded-sm shadow-sm"
            >
              <div
                v-for="productType in productTypes"
                :key="productType"
                class="px-2 py-1 cursor-pointer"
                @click="toggleFilter('productTypeFilter', productType)"
              >
                <fa
                  :icon="faCheck"
                  class="self-center inline-block mr-2"
                  :class="{'text-teal-500':productTypeFilter.includes(productType)}"
                />
                {{ productType }}
              </div>
            </div>
          </th>
          <th
            v-if="platforms.length > 1"
            class="relative hidden w-1/12 p-4 cursor-pointer lg:table-cell"
          >
            <div
              class="flex justify-between"
              @click="showPlatformFilter = !showPlatformFilter"
            >
              <div>Platform</div>
              <fa
                :icon="faFilter"
                class="self-center inline-block"
                :class="{'text-teal-500':platformFilter.length}"
              />
            </div>
            <div
              v-if="showPlatformFilter"
              v-click-outside="hidePlatformFilter"
              class="absolute flex flex-col p-2 mt-2 overflow-visible font-light whitespace-no-wrap bg-white border rounded-sm shadow-sm"
            >
              <div
                v-for="platform in platforms"
                :key="platform"
                class="px-2 py-1 cursor-pointer"
                @click="toggleFilter('platformFilter', platform)"
              >
                <fa
                  :icon="faCheck"
                  class="self-center inline-block mr-2"
                  :class="{'text-teal-500':platformFilter.includes(platform)}"
                />
                {{ platform }}
              </div>
            </div>
          </th>
          <Feature :feature-slug="featureNames.CLIENT_ADVISOR">
            <th class="relative w-1/12 p-4 cursor-pointer">
              <div
                class="flex justify-between"
                @click="showClientAdvisorFilter = !showClientAdvisorFilter"
              >
                <div>C.S.M.</div>
                <fa
                  :icon="faFilter"
                  class="self-center inline-block"
                  :class="{'text-teal-500':clientAdvisorFilter.length}"
                />
              </div>
              <div
                v-if="showClientAdvisorFilter"
                v-click-outside="hideClientAdvisorFilter"
                class="absolute flex flex-col p-2 mt-2 overflow-visible font-light normal-case whitespace-no-wrap bg-white border rounded-sm shadow-sm"
              >
                <div
                  v-for="clientAdvisor in clientAdvisors"
                  :key="clientAdvisor.id"
                  class="px-2 py-1 cursor-pointer"
                  @click="toggleFilter('clientAdvisorFilter',clientAdvisor.id)"
                >
                  <fa
                    :icon="faCheck"
                    class="self-center inline-block mr-2"
                    :class="{'text-teal-500':clientAdvisorFilter.includes(clientAdvisor.id)}"
                  />
                  {{ clientAdvisor.name }}
                </div>
              </div>
            </th>
          </Feature>
        </tr>
      </thead>
      <tbody
        v-if="!filteredCompanies.length"
        class="text-sm"
      >
        <tr v-if="!filteredCompanies.length">
          <td colspan="7">
            <div
              v-if="$apollo.queries.companies.loading"
              class="loading"
            >
              <span style="width: 100%;" />
            </div>
            <div
              v-else
              class="p-8 text-base text-center"
            >
              Zu den eingestellten Filtern oder zur Suche ist kein Eintrag vorhanden.
              <a
                href="#"
                class="underline"
                @click.prevent="clearAllFilter"
              >Alle Filter und Suche löschen</a>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody
        v-else
        class="text-sm"
      >
        <tr
          v-for="company in filteredCompanies"
          :key="company.id"
          class="text-gray-500 transition cursor-pointer duration-250 group even:bg-gray-100 odd:bg-white hover:text-gray-700"
          @click="handleRowClick(company, $event)"
        >
          <td class="hidden w-1/12 px-4 py-2 group-hover:bg-gray-200 sm:table-cell">
            {{ getCompanyStatesName(last(company.state.stringValues)) }}
          </td>

          <td class="w-2/12 px-4 py-2 group-hover:bg-gray-200">
            <span class="font-semibold text-gray-700">{{ company.name }}</span>
            <span
              v-if="company.debtorId"
              class="block text-sm"
            >
              Debitoren ID: {{ company.debtorId }}
            </span>
            <span
              v-else-if="company.customerNumber"
              class="block text-sm"
            >
              Kundennummer: {{ company.customerNumber }}
            </span>
            <span
              v-if="company.slug"
              class="block text-sm"
            >
              Slug: {{ company.slug }}
            </span>
          </td>

          <td class="hidden w-1/12 px-4 py-2 group-hover:bg-gray-200 sm:table-cell">
            <div v-if="company.createdAt">
              {{ formatDate(company.createdAt) }}
            </div>
          </td>

          <td class="hidden w-2/12 px-4 py-2 group-hover:bg-gray-200 sm:table-cell">
            <div v-if="company.address">
              {{ company.address.street }}
              <br>
              {{ company.address.zip }} {{ company.address.city }}
            </div>
          </td>
          <td class="hidden w-1/12 px-4 py-2 group-hover:bg-gray-200 xl:table-cell">
            <div v-if="company.ambassador">
              {{ company.ambassador.firstname }} {{ company.ambassador.lastname }}
              <br>
              {{ company.ambassador.email }}
            </div>
          </td>
          <td
            class="hidden w-2/12 px-4 py-2 group-hover:bg-gray-200 xl:table-cell"
          >
            {{ company.site && company.site.tracking && company.site.tracking.gtm }}
          </td>
          <td
            class="hidden w-1/12 px-4 py-2 uppercase group-hover:bg-gray-200 md:table-cell"
          >
            {{ company.country?.toUpperCase() }}
          </td>
          <Feature
            v-slot="{feature}"
            :feature-slug="featureNames.VARIANT"
          >
            <td
              v-if="hasMultipleVariants(feature.config.variants)"
              class="hidden w-1/12 px-4 py-2 uppercase group-hover:bg-gray-200 lg:table-cell"
            >
              {{ company.variant }}
            </td>
          </Feature>
          <td
            class="hidden w-1/12 px-4 py-2 uppercase group-hover:bg-gray-200 lg:table-cell"
          >
            {{ company.productType }}
          </td>
          <td
            v-if="platforms.length > 1"
            class="hidden w-1/12 px-4 py-2 uppercase group-hover:bg-gray-200 lg:table-cell"
          >
            <ColorBadge :text="company.platform">
              {{ company.platform }}
            </ColorBadge>
          </td>
          <Feature :feature-slug="featureNames.CLIENT_ADVISOR">
            <td class="w-1/12 px-4 py-2 group-hover:bg-gray-200">
              <template v-if="company.clientAdvisor">
                <img
                  :src="company.clientAdvisor.photo"
                  class="w-10 bg-gray-800 rounded-full"
                  :alt="company.clientAdvisor.name"
                  :title="company.clientAdvisor.name"
                >
              </template>
            </td>
          </Feature>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import LIST_COMPANIES from '@/graphql/ListCompanies.gql'
import CLIENT_ADVISORS from '@/graphql/GetClientAdvisors.gql'
import vClickOutside from 'v-click-outside'
import SortableField from '@/components/SortableField.vue'
import ExportProductsModal from '@/components/modals/company/export/export-products.vue'
import ExportPricehubbleModal from '@/components/modals/company/export/export-pricehubble.vue'
import ExportExpertProductsModal from '@/components/modals/company/export/export-expert-products.vue'
import ExportStartedContractsModal from '@/components/modals/company/export/export-started-contract.vue'
import ExportTerminatedContractModal from '@/components/modals/company/export/export-terminated-contract.vue'
import ExportBillingCompanyModal from '@/components/modals/company/export/export-billing-company'
import ExportAbacusCompanyModal from '@/components/modals/company/export/export-abacus-company'
import ExportAbacusContractModal from '@/components/modals/company/export/export-abacus-contract'
import ExportCompaniesWithInvoicesModal from '@/components/modals/company/export/export-companies-with-invoices'
import ExportCompaniesWithoutInvoicesModal from '@/components/modals/company/export/export-companies-without-invoices'
import { faFilter, faCheck, faFileCsv } from '@fortawesome/free-solid-svg-icons'
import { exportCompanies, exportUsers, exportContractsWithProducts } from '@/lib/export'
import { getCompanyStatesName, COMPANY_STATES } from '@/config'
import featureMixin from '@/mixins/feature'
import variantMixin from '@/mixins/variant'
import ColorBadge from '@/components/common/ColorBadge.vue'

const escapeRegxChars = (value) =>
  value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

export default {
  name: 'Companies',
  components: {
    SortableField, ColorBadge
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mixins: [featureMixin, variantMixin],
  data () {
    return {
      variants: [],
      productTypes: [],
      countries: [],
      platforms: [],
      faFilter,
      faFileCsv,
      faCheck,
      clientAdvisors: [],
      companies: [],
      clientAdvisorFilter: [],
      showClientAdvisorFilter: false,
      countryFilter: [],
      showCountryFilter: false,
      variantFilter: [],
      productTypeFilter: [],
      platformFilter: [],
      showVariantFilter: false,
      showProductTypeFilter: false,
      showPlatformFilter: false,
      stateFilter: [],
      showStateFilter: false,
      search: '',
      showExportModal: false
    }
  },
  computed: {
    filteredCompanies () {
      let filtered = this.companies

      if (this.clientAdvisorFilter.length) {
        filtered = filtered.filter(this.filterByClientAdvisor)
      }

      if (this.countryFilter.length) {
        filtered = filtered.filter(this.filterByCountry)
      }

      if (this.variantFilter.length) {
        filtered = filtered.filter(this.filterByVariant)
      }

      if (this.productTypeFilter.length) {
        filtered = filtered.filter(this.filterByProductType)
      }

      if (this.platformFilter.length) {
        filtered = filtered.filter(this.filterByPlatform)
      }

      if (this.stateFilter.length) {
        filtered = filtered.filter(this.filterByState)
      }

      if (this.search.length) {
        filtered = filtered.filter(this.filterBySearch)
      }

      return filtered
    }
  },
  apollo: {
    companies: {
      query: LIST_COMPANIES,
      result ({ data }) {
        if (!data?.companies) return

        const uniqueValues = data.companies.reduce((acc, company) => {
          acc.productTypes.add(company.productType)
          acc.countries.add(company.country)
          acc.platforms.add(company.platform)
          return acc
        }, {
          productTypes: new Set(),
          countries: new Set(),
          platforms: new Set()
        })

        // Convert Sets to arrays
        this.productTypes = Array.from(uniqueValues.productTypes).filter(Boolean)
        this.countries = Array.from(uniqueValues.countries).filter(Boolean)
        this.platforms = Array.from(uniqueValues.platforms).filter(Boolean)
      },
      fetchPolicy: 'cache-and-network'
    },
    clientAdvisors: {
      query: CLIENT_ADVISORS,
      fetchPolicy: 'cache'
    }
  },
  created () {
    this.COMPANY_STATES = COMPANY_STATES
    this.variants = this.getVariants()
  },
  mounted () {
    this.popupItem = this.$el
    this.restoreFilter('stateFilter')
    this.restoreFilter('countryFilter')
    this.restoreFilter('variantFilter')
    this.restoreFilter('productTypeFilter')
    this.restoreFilter('platformFilter')
    this.restoreFilter('clientAdvisorFilter')
  },
  methods: {
    last: (list) => list[list.length - 1],
    exportContractsWithProducts,
    getCompanyStatesName,
    async exportCompanies ({ extended }) {
      return await exportCompanies({ extended })
    },
    async exportUsers () {
      return exportUsers()
    },
    showExportProductsModal () {
      this.$modal.show(ExportProductsModal)
    },
    showExportPricehubbleModal () {
      this.$modal.show(ExportPricehubbleModal)
    },
    showExportExpertProductsModal () {
      this.$modal.show(ExportExpertProductsModal)
    },
    showExportStartedContractsModal () {
      this.$modal.show(ExportStartedContractsModal)
    },
    showExportTerminatedContractModal () {
      this.$modal.show(ExportTerminatedContractModal)
    },
    showExportBillingCompanyModal () {
      this.$modal.show(ExportBillingCompanyModal)
    },
    showExportAbacusCompanyModal () {
      this.$modal.show(ExportAbacusCompanyModal)
    },
    showExportAbacusContractModal () {
      this.$modal.show(ExportAbacusContractModal)
    },
    showExportCompaniesWithInvoiceModal () {
      this.$modal.show(ExportCompaniesWithInvoicesModal)
    },
    showExportCompaniesWithoutInvoiceModal () {
      this.$modal.show(ExportCompaniesWithoutInvoicesModal)
    },
    hideExportModal () {
      this.showExportModal = false
    },
    hideClientAdvisorFilter () {
      this.showClientAdvisorFilter = false
    },
    hideVariantFilter () {
      this.showVariantFilter = false
    },
    hideProductTypeFilter () {
      this.showProductTypeFilter = false
    },
    hidePlatformFilter () {
      this.showPlatformFilter = false
    },
    hideStateFilter () {
      this.showStateFilter = false
    },
    hideCountryFilter () {
      this.showCountryFilter = false
    },
    restoreFilter (key) {
      if (localStorage.getItem(key)) {
        try {
          this[key] = JSON.parse(localStorage.getItem(key))
        } catch (e) {
          localStorage.removeItem(key)
        }
      }
    },
    saveFilter (key) {
      localStorage.setItem(key, JSON.stringify(this[key]))
    },
    toggleFilter (filterName, item) {
      if (!this[filterName].includes(item)) {
        this[filterName].push(item)
      } else {
        this[filterName].splice(this[filterName].indexOf(item), 1)
      }
      this.saveFilter(filterName)
    },
    filterByCountry ({ country }) {
      return this.countryFilter.includes(country)
    },
    filterByClientAdvisor ({ clientAdvisor }) {
      return (
        clientAdvisor && this.clientAdvisorFilter.includes(clientAdvisor.id)
      )
    },
    filterByVariant ({ variant }) {
      return this.variantFilter.includes(variant)
    },
    filterByProductType ({ productType }) {
      return this.productTypeFilter.includes(productType)
    },
    filterByPlatform ({ platform }) {
      return this.platformFilter.includes(platform)
    },
    filterByState ({ state: { stringValues } }) {
      return this.stateFilter.includes(this.last(stringValues))
    },
    filterBySearch ({
      customerNumber,
      name,
      slug,
      ambassador,
      debtorId,
      address,
      site,
      state: { value: state }
    }) {
      const ambassadorString = [ambassador.firstname, ambassador.lastname, ambassador.email].join(' ')
      const { street, zip, city } = address || {}

      const searchableFields = [
        ambassadorString,
        customerNumber,
        name,
        slug,
        street,
        city,
        zip,
        debtorId,
        getCompanyStatesName(state),
        site.tracking && site.tracking.gtm
      ]

      const regx = new RegExp(escapeRegxChars(this.search), 'i')

      return searchableFields.some((value) => regx.test(value))
    },
    clearAllFilter () {
      this.variantFilter = []
      this.saveFilter('variantFilter')
      this.platformFilter = []
      this.saveFilter('platformFilter')
      this.productTypeFilter = []
      this.saveFilter('productTypeFilter')
      this.stateFilter = []
      this.saveFilter('stateFilter')
      this.countryFilter = []
      this.saveFilter('countryFilter')
      this.clientAdvisorFilter = []
      this.saveFilter('clientAdvisorFilter')
      this.search = ''
    },

    /**
     * We simulate normal link behavior when using cmd/ctrl + click
     * to open the details in a new tab.
     */
    handleRowClick (company, event) {
      const href = this.$router.resolve({
        name: 'company',
        params: { id: company.id }
      }).href

      if (event.metaKey || event.ctrlKey) {
        window.open(href, '_blank')
      } else {
        this.$router.push(href)
      }
    }
  }
}
</script>

<style >
.loading {
  height: 5px;
  position: relative;
}
.loading span {
  display: block;
  height: 100%;
  background-color: rgb(43, 194, 83);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgb(43, 194, 83)),
    color-stop(1, rgb(84, 240, 84))
  );
  background-image: -moz-linear-gradient(
    center bottom,
    rgb(43, 194, 83) 37%,
    rgb(84, 240, 84) 69%
  );
  position: relative;
  overflow: hidden;
  width: 0;
  animation: expandWidth 1s linear;
}
.loading > span:after {
  content: "";
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
}
@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
@keyframes expandWidth {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
